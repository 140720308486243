import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from 'gatsby'
import Swiper from 'react-id-swiper'

// styles
import '../SectionBlogPost/sectionBlogPost.scss'

const SectionCursos = ({ path }) => {
  const data = useStaticQuery(
    graphql`{
  eventos: allContentfulSpaniQualifica(sort: {fields: dataInicio, order: ASC}) {
    edges {
      node {
        id
        nome
        slug
        dataInicio
        dataFim
        certificacao
        localizacao
        cargaHoraria
        preRequisitos {
          internal {
            content
          }
        }
        card {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        vagas
        valor
      }
    }
  }
}
`
  )

  const dataAtual = new Date().toISOString()

  const _cursos = data.eventos.edges.filter(
    edge => edge.node.slug !== path && dataAtual < new Date(edge.node.dataInicio).toISOString())

  const params = {
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 3,
        spaceBetween: 10
      }
    }
  }

  function getNomeMes (id) {
    const monthNames = ['janeiro', 'fevereiro', 'marco', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro']
    return monthNames[id]
  }

  const mouths = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]

  const CardCursos = (props) => {
    // const dataAtual = new Date().toISOString()
    return <>
      <div className='sizeimg-post-blog'>
        <GatsbyImage image={props.image} alt={props.slug} className='w-100 h-100' />
      </div>
      <div className='position-absolute d-flex justify-content-center alignt-items-center flex-column text-center text-uppercase circle-blog'>
        <p className='text-capitalize'>{props.date}</p>
        <p className='text-uppercase'>{props.month}</p>
      </div>
      {/* <div className='position-absolute d-flex justify-content-center alignt-items-center flex-column text-center text-uppercase finalizadoCursos'>
        {dataAtual > props.dataInicio &&
          <div className="d-flex bg-gray2 align-items-center justify-content-center py-1 px-4 mt-2 h-100">
            <p className="text-center mb-0 font-weight-bold w-75 fonterror">
              Finalizado
            </p>
          </div>}
      </div> */}
      <div className='caption-blog'>
        <h4 className='pb-1 pt-1 mt-2'>{props.title}</h4>
        <Link to={`/spani-qualifica/${props.diaMes}/${props.slug}-${props.id}/`} className='btn btn-blog d-flex justify-content-center align-items-center text-center text-uppercase'>
          Leia Mais
        </Link>
      </div>
    </>;
  }

  return <>
    {_cursos.length !== 0 &&
    <div className='pt-4 pb-4'>
      <div className='container '>
        <div className='row'>
          <div className='col-12 header-blog'>
            <h2 className="text-black">Veja mais Cursos</h2>
          </div>
        </div>
        <div id='carosel-container_settings_cursos'>
          <div className='pt-lg-2'>
            <Swiper {...params}>
              {
                _cursos.map((post, index) => {
                  return (
                    <div className='pb-4' key={index}>
                      <CardCursos
                        slug={post.node.slug}
                        image={post.node.card.localFile.childImageSharp.gatsbyImageData}
                        id={post.node.id}
                        date={new Date(post.node.dataInicio).getDate()}
                        month={mouths[new Date(post.node.dataInicio).getMonth()]}
                        title={post.node.nome}
                        dataInicio={new Date(post.node.dataInicio).toISOString()}
                        diaMes={getNomeMes(new Date(post.node.dataInicio).getMonth())}
                        className='d-none d-sm-block'
                      />
                    </div>
                  );
                })
              }
            </Swiper>
          </div>
        </div>
      </div>
    </div>
    }
  </>;
}
export default SectionCursos
