/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout/index'
import Pagina from '../../components/Pagina'
import './spani-qualifica.scss'
import iconeData from '../../images/calendario/iconeData.svg'
import iconeLocalizacao from '../../images/calendario/iconeLocalizacao.svg'
import iconeRelogio from '../../images/calendario/iconeRelogio.svg'
import SectionCursos from '../../components/SectionCursos/sectionCursos'

const spaniQualifica = ({ data, pageContext }) => {
  function getNomeMes () {
    const monthNames = ['janeiro', 'fevereiro', 'marco', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro']
    return monthNames[pageContext.cursosId - 1]
  }

  function handlePage (link) {
    if (typeof window !== 'undefined') {
      window.location.replace(`/spani-qualifica/${link}/`)
    }
  }

  const cursos = data.eventos.edges
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  const [mesSelecionado, setMesSelecionado] = useState()
  useEffect(() => {
    setMesSelecionado(getNomeMes(cursos.dataInicio))
  }, [])
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Home' },
      { path: `/spani-qualifica/${getNomeMes(cursos.dataInicio)}/`, name: 'Spani Qualifica' }
    ]
  }

  function toMonth (date) {
    const data = new Date(date)
    return data.toLocaleDateString('pt-br', { day: 'numeric', month: 'numeric', year: 'numeric' })
  }

  function toHMS (date) {
    const data = new Date(date)
    const z = n => ('0' + n).slice(-2)
    return `${z(data.getHours(-3))}:${z(data.getMinutes())}`
  }

  return (
    <Layout breadCrumb={breadC} >
      <Pagina pagina={data.pagina} />
      <div className="container">
        <div className="row">
          <div className="col-12 pt-2">
            <h2 className="text-black">O que é?</h2>
            <p>É um programa do Spani Atacadista, em parceria com o Sebrae e a Indústria, para ajudar na qualificação/capacitação dos clientes, comerciantes, empreendedores e pessoas que desejam melhorar o seu negócios através da profissionalização em diversas frentes de atuação e necessidades.</p>
          </div>
          <div className="col-12 pt-4 pb-lg-2">
            <h2 className="text-black">Confira os cursos</h2>
            <hr/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 pt-1 mb-3 order-lg-first order-last">
            <p>Exibindo {cursos.length} de {pageContext.tamanhoTotal} resultados</p>
          </div>
          <div className="col-lg-4 d-flex mb-3 pt-1 order-lg-last order-first">
            <p className="width-custom">Escolher o mês: </p>
            <select type="text" id="mesSelecionado" name="mesSelecionado" onChange={(e) => handlePage(e.target.value)} className="w-100 h-100 select-custom-qualifica" value={mesSelecionado}>
              <option value='janeiro'>Janeiro</option>
              <option value='fevereiro'>Fevereiro</option>
              <option value='marco'>Março</option>
              <option value='abril'>Abril</option>
              <option value='maio'>Maio</option>
              <option value='junho'>Junho</option>
              <option value='julho'>Julho</option>
              <option value='agosto'>Agosto</option>
              <option value='setembro'>Setembro</option>
              <option value='outubro'>Outubro</option>
              <option value='novembro'>Novembro</option>
              <option value='dezembro'>Dezembro</option>
            </select>
          </div>
        </div>
        <div className="row">
          {cursos.map((cursos, index) => {
            const dataAtual = new Date().toISOString()
            const dataInicio = new Date(cursos.node.dataInicio).toISOString()
            return (
              <div className="col-lg-3 col-12 mb-3" key={index}>
                <Link to={`/spani-qualifica/${pageContext.cursosSlug}/${cursos.node.slug}-${cursos.node.id}/`} className="hoverCursos">
                  <div className="cards-qualifica p-3">
                    <p className="font-weight-bold mb-2">{cursos.node.nome}</p>
                    <div className="d-flex pb-2"><img src={iconeData} alt="Icone de data" className="pr-2" /><p>{toMonth(cursos.node.dataInicio)}  {cursos.node.dataFim && 'a ' + toMonth(cursos.node.dataFim)}</p></div>
                    <div className="d-flex pb-2"><img src={iconeRelogio} alt="Icone de relogio" className="pr-2" /><p>{toHMS(cursos.node.dataInicio)} - {toHMS(cursos.node.dataFim)}</p></div>
                    <div className="d-flex"><img src={iconeLocalizacao} alt="Icone de localização" className="pr-2 align-items-start d-flex h-100" /><p>{cursos.node.localizacao}</p></div>
                    {dataAtual > dataInicio &&
                      <div className="d-flex align-items-center justify-content-center py-1 px-4 mt-2 rounded h-100" style={{ backgroundColor: 'rgb(0 0 0 / 25%)' }}>
                        <p className="text-center text-white mb-0 font-weight-bold w-75 fonterror">
                          Finalizado
                        </p>
                      </div>}
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
        <div className='d-flex justify-content-center align-items-center py-3'>
          <Link
            to={`/spani-qualifica/${pageContext.cursosSlug}/${isFirst ? '' : `${prevPage}`}`}
            rel="prev"
            className={isFirst ? 'disabled-link bg-transparent border-0 px-4' : 'bg-transparent border-0 px-4'}
          >
            <i className={!isFirst ? 'arrow-dark arrow-left' : 'arrow-gray arrow-left'}></i>
          </Link>
          <div className="row justify-content-center">
            {Array.from({ length: numPages }, (_, i) => (
              <div className="col d-flex justify-content-center px-2 p-sm-3" >
                <span>
                  <Link
                    key={`pagination-number${i + 1}`}
                    to={`/spani-qualifica/${pageContext.cursosSlug}/${i === 0 ? '' : `${i + 1}/`}`}
                    className={i + 1 === currentPage ? 'rounded px-3 py-2 text-white bg-blue border-0' : 'px-3 py-2 border-0 bg-transparent'}
                    style={{ outline: 'none' }}
                  >
                    {i + 1}
                  </Link>
                </span>
              </div>

            ))}
          </div>
          <Link
            to={`/spani-qualifica/${pageContext.cursosSlug}/${isLast ? `${currentPage === 1 ? '' : currentPage}` : `${nextPage}/`}`}
            rel="next"
            className={isLast ? 'disabled-link bg-transparent border-0 px-4' : 'bg-transparent border-0 px-4'}
          >
            <i className={!isLast ? 'arrow-dark arrow-right' : 'arrow-gray arrow-right'}></i>
          </Link>
        </div>
      </div>
      <SectionCursos />
    </Layout>
  )
}

export default spaniQualifica

export const query = graphql`query ($skip: Int!, $limit: Int!, $cursosId: Int!) {
  eventos: allContentfulSpaniQualifica(
    filter: {filtroDoMes: {numeroDoMes: {eq: $cursosId}}}
    sort: {fields: dataInicio, order: ASC}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        id
        nome
        slug
        dataInicio
        dataFim
        certificacao
        localizacao
        cargaHoraria
        preRequisitos {
          internal {
            content
          }
        }
        vagas
        valor
      }
    }
  }
  pagina: contentfulPagina(path: {eq: "/spani-qualifica/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
    desktop {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    mobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 414, height: 310, layout: CONSTRAINED)
        }
      }
    }
  }
}
`
